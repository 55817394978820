import { cn } from '@ngg/storefront-utils';
import { InputBaseProps, Input as NGGInput } from '@ngg/components';
import { forwardRef } from 'react';

type InputBasePropsWithPassword = InputBaseProps & {
  passwordrules?: string;
};

const Input = forwardRef<HTMLInputElement, InputBasePropsWithPassword>(
  function Input(
    { label, id, classNames, endAdornment, required, ...props },
    ref,
  ) {
    const isInvalid = props['aria-invalid'] === true;
    return (
      <NGGInput
        ref={ref}
        required={required}
        placeholder={label}
        endAdornment={
          <>
            {label ? (
              <label
                htmlFor={id}
                className={cn(
                  'text-grey-300 pointer-events-none absolute bottom-2.5 left-4 top-3 text-sm',
                  'transition-transform peer-focus:-translate-y-3 peer-focus:text-xxxs',
                  'peer-[&:not(:placeholder-shown)]:-translate-y-3 peer-[&:not(:placeholder-shown)]:text-xxxs',
                  isInvalid && 'text-error',
                  classNames?.label,
                )}>
                {label}
                {required && '*'}
              </label>
            ) : null}
            {endAdornment}
          </>
        }
        classNames={{
          ...classNames,
          hint: cn('pl-4', isInvalid && 'text-error', classNames?.hint),
          input: cn(
            'w-full peer px-4 pt-4 pb-0.5 h-10 text-sm lg:text-xs border border-gray-600',
            'placeholder:text-transparent bg-white',
            'focus:outline-none',
            isInvalid && 'border-error',
            classNames?.input,
          ),
        }}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export default Input;
