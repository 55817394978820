'use client';

import { Maybe } from '@business/gql/graphql';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from '@ngg/components';
import { Dictionary } from '@ts/dictionary';
import Link from '@ui/components/Link/Link';
import Input from '@ui/components/inputs/Input';
import currencies from '@utils/currency/currencies';
import { ReactNode, useState } from 'react';

import config from '@utils/config';
import { Heading } from '@ui/components/Heading/Heading';
import Paragraph from '@ui/components/Paragraph/Paragraph';

export default function MatchPrice({
  dictionary,
  reference,
  children,
}: {
  dictionary: Dictionary;
  reference: Maybe<string> | undefined;
  children?: ReactNode;
}) {
  const [state, handleSubmit] = useForm(reference || '');
  const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null);
  const { locale, language } = config;
  const currency = currencies[language];

  if (state.succeeded) {
    return (
      <>
        <Heading className="text-lg">
          {dictionary?.matchPriceFormSuccessHeading}
        </Heading>
        <Paragraph className="mt-4 text-sm">
          {dictionary?.matchPriceFormSuccessText}
        </Paragraph>
      </>
    );
  }

  const isInputError = (inputName: string) =>
    // @ts-ignore
    state.errors?.some((error) => error.field === inputName);

  return (
    <>
      {children}
      <form
        onSubmit={handleSubmit}
        className="not-prose grid max-w-4xl grid-cols-1 gap-x-3 md:grid-cols-2"
        encType="multipart/form-data">
        <div className="space-y-3 md:mt-7">
          <Input
            type="text"
            id="productVariant"
            label={dictionary?.matchPriceFormProductVariantInputLabel}
            name="Product Variant"
            fullWidth
            classNames={{
              input: isInputError('Product Variant') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-xs"
            field="Product Variant"
            errors={state.errors}
          />
          <Input
            type="number"
            id="price"
            name="Price"
            label={dictionary?.matchPriceFormPriceInputLabel}
            required
            fullWidth
            endAdornment={
              <span className="absolute bottom-2 right-2 top-2 flex items-center p-2 text-xs font-bold uppercase">
                {currency}
              </span>
            }
            classNames={{
              input: isInputError('Price') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-xs"
            field="Price"
            errors={state.errors}
          />
          <Input
            type="text"
            id="name"
            name="Name"
            label={dictionary?.matchPriceFormNameInputLabel}
            required
            fullWidth
            classNames={{
              input: isInputError('Name') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-xs"
            field="Name"
            errors={state.errors}
          />
          <Input
            type="email"
            id="email"
            name="Email"
            label={dictionary?.matchPriceFormEmailInputLabel}
            required
            fullWidth
            classNames={{
              input: isInputError('Email') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-xs"
            field="Email"
            errors={state.errors}
          />
        </div>

        <hr className="my-6 bg-black/25 md:hidden" />

        <div className="space-y-3">
          <Paragraph className="text-xs">
            {dictionary?.matchPriceFormLinkToResellerInputText}
          </Paragraph>

          <Input
            type="url"
            id="linkToReseller"
            name="Link To Reseller"
            label={dictionary?.matchPriceFormLinkToResellerInputLabel}
            fullWidth
            classNames={{
              wrapper: 'mt-2',
              input: isInputError('Link To Reseller') ? 'border-error' : '',
            }}
          />
          <ValidationError
            className="mt-2 text-sm"
            field="Link To Reseller"
            errors={state.errors}
          />

          <div className="relative">
            <Input
              type="text"
              readOnly
              label={
                uploadedFiles?.length
                  ? uploadedFiles[0].name
                  : dictionary?.matchPriceFormUploadInputLabel
              }
              fullWidth
              classNames={{
                label: uploadedFiles?.length ? 'text-black' : '',
                input: isInputError('Uploaded Offer') ? 'border-error' : '',
              }}
              endAdornment={
                <div className="pointer-events-none absolute bottom-2 right-2 top-2 flex items-center bg-black p-2 text-xs font-medium text-white">
                  {dictionary?.formUploadEndAdornmentText}
                </div>
              }
            />

            <input
              name="Uploaded Offer"
              id="file"
              type="file"
              onChange={(e) => setUploadedFiles(e.target.files)}
              className="absolute inset-0 opacity-0"
            />
            <ValidationError
              className="mt-2 text-sm"
              field="Uploaded Offer"
              errors={state.errors}
            />
          </div>

          <Button
            type="submit"
            disabled={state.submitting}
            fullWidth
            className="btn-primary disabled:border-gray-150 disabled:bg-grey-150 disabled:text-grey-300 h-10 grow bg-black text-base text-white disabled:opacity-100">
            {dictionary?.formSendButtonTextMatchPrice}
          </Button>

          <Paragraph className="mt-4 text-sm">
            {dictionary?.matchPriceFormTermsText}
          </Paragraph>

          <Paragraph className="mt-2 text-sm">
            <Link
              locale={locale}
              href={dictionary?.matchPriceFormReadMoreLink}
              className="underline">
              {dictionary?.matchPriceFormReadMoreLinkText}
            </Link>
          </Paragraph>
        </div>

        {/* Honeypot */}
        <input type="text" name="_gotcha" className="hidden" />
      </form>
    </>
  );
}
